import { LIB } from "./consts-lib";
import { getLanguageCode } from "../services/service-utils";

export const postfix = " | SMK Open";

export const getLangPath = () => {
  let lang = getLanguageCode();
  if (lang === "da") return "";
  return "/en";
};

const langPath = getLangPath();

export const routes = Object.freeze({
  home: {
    pathname: langPath + "/",
    route: langPath + "/",
    title: LIB.PAGE_TITLES.home
  },
  search: {
    pathname: langPath + "/art",
    route: langPath + "/art",
    title: `${LIB.PAGE_TITLES.search}${postfix}`
  },
  filter_by_color: {
    pathname: langPath + "/art/color",
    route: langPath + "/art/color",
    title: `${LIB.PAGE_TITLES.filter_by_color}${postfix}`
  },
  filter_by_person: {
    pathname: langPath + "/art/person",
    route: langPath + "/art/person",
    title: `${LIB.PAGE_TITLES.filter_by_person}${postfix}`
  },
  filter_by_advanced: {
    pathname: langPath + "/art/advanced",
    route: langPath + "/art/advanced/:facet?",
    title: `${LIB.PAGE_TITLES.filter_by_advanced}${postfix}`
  },
  artwork: {
    basepath: langPath + "/artwork",
    pathname: langPath + "/artwork/image",
    pathname_iiif: langPath + "/artwork/iiif",
    pathname_3d: langPath + "/artwork/3d",
    pathname_parts: langPath + "/artwork/parts",
    pathname_exhibition: langPath + "/artwork/exhibition",
    route: langPath + "/artwork/:view/:object_number/:part?",
    title: null
  },
  my_list: {
    pathname: langPath + "/my-list",
    route: langPath + "/my-list",
    title: `${LIB.PAGE_TITLES.my_list}${postfix}`
  },
  shared_list: {
    pathname: langPath + "/shared-list",
    route: langPath + "/shared-list",
    title: `${LIB.PAGE_TITLES.shared_list}${postfix}`
  },
  themes: {
    pathname: langPath + "/themes",
    route: langPath + "/themes",
    title: `${LIB.PAGE_TITLES.themes}${postfix}`
  },
  theme: {
    pathname: langPath + "/theme",
    route: langPath + "/theme/:themeid",
    title: null
  }
});
