import React, { Component, Suspense } from "react";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";
import { routes } from "../constants/consts-routes.js";
import { StateProvider } from "../StateContext";
import {
  apiSearchArt,
  apiPaginateArt,
  apiGetArtwork
} from "../services/service-api";
import {
  getUrlParams,
  replaceUrlParams
} from "../services/service-url-search-params";

import {
  addToList,
  clearMyList,
  getMyList,
  getMyListTitle,
  removeFromMyList,
  setMyListTitle
} from "../services/service-my-list";
import { getCookie, setCookie } from "../services/service-cookies";
import { cookies } from "../constants/consts-cookies";
import { searchLayoutType } from "../constants/consts-search-layout";
import {
  isMobile,
  isSearchFilterRoute,
  isSearchOrFilterRoute
} from "../services/service-utils";
// import { analyticsEvent, gtmPageView } from "../services/service-gtm";

const SearchPage = React.lazy(() => import("../routes/SearchPage"));
const ArtworkPage = React.lazy(() =>
  import("../routes/ArtworkPage").then(module => ({
    default: module.ArtworkPage
  }))
);
const MyListPage = React.lazy(() => import("../routes/MyListPage"));
const SharedListPage = React.lazy(() => import("../routes/SharedListPage"));
const ThemesPage = React.lazy(() => import("../routes/ThemesPage"));
const ThemePage = React.lazy(() => import("../routes/ThemePage"));

class App extends Component {
  constructor(props) {
    super(props);
    // If you open / refresh the search page on mobile,
    // with facets open, we force close the facets and
    // redirect to the main search page
    if (isMobile() && isSearchFilterRoute()) {
      this.props.history.replace(
        `${routes.search.pathname}${this.props.location.search}`
      );
    }

    this.getArtwork = this.getArtwork.bind(this);
    this.searchArt = this.searchArt.bind(this);
    this.getSearchLayoutFromCookie = this.getSearchLayoutFromCookie.bind(this);
    this.setSearchLayout = this.setSearchLayout.bind(this);
    this.addToMyList = this.addToMyList.bind(this);
    this.removeFromMyList = this.removeFromMyList.bind(this);
    this.clearMyList = this.clearMyList.bind(this);
    this.setMyListTitle = this.setMyListTitle.bind(this);
    this.onFirstArtworkClick = this.onFirstArtworkClick.bind(this);

    // const { location } = props;

    // const pathname = location.pathname.split("?")[0];
    // gtmPageView(pathname);

    this.getSearchLayoutFromCookie();

    this.state = {
      art: null,
      artwork: null,
      myList: null,
      myListTitle: null,
      lastSearch: null,
      lastScrollValue: null,
      searchLayoutId: searchLayoutType.masonry,
      searchArt: this.searchArt,
      getArtwork: this.getArtwork,
      setSearchLayout: this.setSearchLayout,
      addToMyList: this.addToMyList,
      removeFromMyList: this.removeFromMyList,
      clearMyList: this.clearMyList,
      setMyListTitle: this.setMyListTitle,
      onFirstArtworkClick: this.onFirstArtworkClick,
      disableAnimation: false
    };
  }

  componentDidMount() {
    if (isSearchOrFilterRoute()) {
      const params = getUrlParams();

      if (params.page && parseInt(params.page) !== 0) {
        replaceUrlParams(
          this.props.history,
          { ...params, page: 0 },
          false,
          null,
          false
        );
      } else {
        this.searchArt();
      }
    }

    this.loadMyList();
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;

    const prevPathname = prevProps.location.pathname.split("?")[0];
    const pathname = location.pathname.split("?")[0];

    if (prevPathname !== pathname) {
      // gtmPageView(pathname);
    }

    const isSearchRoute = isSearchOrFilterRoute();

    // if (isSearchRoute && this.state.artwork) {
    //   this.setState({
    //     artwork: null
    //   });
    // }

    const prevSearch = this.state.lastSearch;
    const nextSearch = location.search;

    if (isSearchRoute && prevPathname !== pathname) {
      if (Boolean(nextSearch !== prevSearch)) {
        window.scrollTo(0, 0);
        this.searchArt();
      } else {
        window.scrollTo(0, this.state.lastScrollValue);
      }
    } else if (isSearchRoute && prevPathname === pathname) {
      if (Boolean(nextSearch !== prevSearch)) {
        const nextParams = getUrlParams();
        const nextPage = Number(nextParams.page || "0");
        if (nextPage) {
          this.paginateArt();
        } else {
          this.searchArt();
        }
      } else {
        if (this.state.art === null) {
          this.searchArt();
        }
      }
    }
  }

  async searchArt() {
    // const nextParams = getUrlParams();
    // const query = nextParams.q !== undefined ? nextParams.q : "*";

    const nextArt = await apiSearchArt();

    if (nextArt) {
      // analyticsEvent("Art", "Search", query, nextArt.found);

      this.setState({
        lastSearch: this.props.location.search,
        items: nextArt.items,
        art: nextArt,
        artwork: null,
        disableAnimation: false
      });
    }
  }

  async paginateArt() {
    const nextArt = await apiPaginateArt();
    this.setState({
      lastSearch: this.props.location.search,
      items: [...(this.state.items || []), ...nextArt.items],
      disableAnimation: false
    });
  }

  async getArtwork(object_number) {
    // check if SITE_DATA (artwork data) is already loaded by lambda function and placed in SITE_DATA variable - otherwise load it:
    const { SITE_DATA } = window;
    const hasSiteData =
      SITE_DATA &&
      SITE_DATA.items &&
      SITE_DATA.items[0] &&
      SITE_DATA.items[0].object_number === object_number;

    const artwork = hasSiteData
      ? SITE_DATA
      : await apiGetArtwork(object_number);
    // console.log('artwork', artwork)

    if (artwork === "solr is down") {
      this.setState({
        artwork: null
      });
    } else {
      // console.log('artwork items', artwork.items.length)
      this.setState({
        artwork:
          artwork && artwork.items && artwork.items.length === 1
            ? artwork.items[0]
            : []
      });
    }
  }

  getSearchLayoutFromCookie() {
    const cookieValue = getCookie(cookies.search_layout_id);
    if (cookieValue) {
      this.setState({
        searchLayoutId: cookieValue
      });
    }
  }

  setSearchLayout(searchLayoutType) {
    this.setState(
      prevState => {
        return {
          searchLayoutId: searchLayoutType
        };
      },
      () => {
        const cookieValue = this.state.searchLayoutId;
        setCookie(cookies.search_layout_id, cookieValue, 365);
      }
    );
  }

  loadMyList() {
    const myList = getMyList();
    const myListTitle = getMyListTitle();

    this.setState({
      myList,
      myListTitle
    });
  }

  addToMyList(object_number) {
    const myList = addToList(object_number);

    this.setState({
      myList: myList
    });
  }

  removeFromMyList(object_number) {
    const myList = removeFromMyList(object_number);

    this.setState({
      myList: myList
    });
  }

  clearMyList() {
    clearMyList();
    this.setMyListTitle(null);

    this.setState({
      myList: null
    });
  }

  setMyListTitle(title) {
    setMyListTitle(title);

    this.setState({
      myListTitle: title
    });
  }

  onFirstArtworkClick(object_number) {
    const { location } = this.props;
    const pathname = location.pathname.split("?")[0];
    const isSearchRoute = isSearchOrFilterRoute(pathname);

    this.setState({
      lastScrollValue: window.scrollY,
      disableAnimation: true
    });

    if (!isSearchRoute) return;

    if (this.state.artwork === null) {
      // console.log("--- click first");
      // analyticsEvent("Art", "FirstArtworkClick", object_number);
    }
  }

  render() {
    const { location } = this.props;

    return (
      <StateProvider value={this.state}>
        <Suspense fallback={<div />}>
          <Switch location={location}>
            <Route exact path={routes.home.route}>
              <SearchPage meta={routes.home} />
            </Route>
            <Route exact path={routes.filter_by_advanced.route}>
              <SearchPage meta={routes.filter_by_advanced} />
            </Route>
            <Route exact path={routes.search.route}>
              <SearchPage meta={routes.search} />
            </Route>
            <Route exact path={routes.artwork.route}>
              <ArtworkPage meta={routes.artwork} />
            </Route>
            <Route exact path={routes.my_list.route}>
              <MyListPage meta={routes.my_list} />
            </Route>
            <Route exact path={routes.shared_list.route}>
              <SharedListPage meta={routes.shared_list} />
            </Route>
            <Route exact path={routes.themes.route}>
              <ThemesPage meta={routes.themes} />
            </Route>
            <Route exact path={routes.theme.route}>
              <ThemePage meta={routes.theme} />
            </Route>

            <Redirect to={routes.home.pathname} />
          </Switch>
        </Suspense>
        {/* <Cookies /> */} {/* Replaced by Cookie Infomation solution */}
      </StateProvider>
    );
  }
}

export default withRouter(App);
