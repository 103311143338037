import { getLanguageCode, getRandomId } from "./service-utils";
import { getUrlParams } from "./service-url-search-params";
import { filterKeys, paramKeys } from "../constants/const-param-keys";

const randomHighlightsId = getRandomId();

export const API_ROOT = process.env.REACT_APP_API_ROOT;
export const ENDPOINT_ART_SEARCH = `/art/search/`;
export const ENDPOINT_PERSON_SEARCH = `/person/search/`;
export const ENDPOINT_ARTWORK = `/art/`;
export const ENDPOINT_PERSON = `/person/`;
export const ENDPOINT_GEO = `/geo_id/`;

const FACETS_UNLIMITED = [
  filterKeys.creator,
  filterKeys.artist,
  filterKeys.colors,
  filterKeys.role_attributed_to,
  filterKeys.role_earlier_ascribed_to,
  filterKeys.role_workshop_of,
  filterKeys.role_follower,
  filterKeys.role_after,
  filterKeys.role_school,
  filterKeys.role_imitator_of,
  filterKeys.role_copy_after,
  filterKeys.role_after_model_by,
  filterKeys.role_publisher,
  filterKeys.role_printer,
  filterKeys.role_artist,
  filterKeys.creator_nationality,
  filterKeys.creator_gender,
  filterKeys.content_person,
  filterKeys.content_subject,
  filterKeys.object_names,
  filterKeys.techniques,
  filterKeys.materials,
  filterKeys.medium
];
const FACETS_LIMITED = [];

const lang = getLanguageCode();
let API_SEARCH_ART_COUNT = 0;
export const rows = 24;

export const getTotalNumberOfArtworks = async () => {
  try {
    const response = await fetch(
      `${API_ROOT}${ENDPOINT_ART_SEARCH}?keys=*&filters=has_image:true&rows=1`
    );
    const data = await response.json();
    return data.found;
  } catch {
    return undefined;
  }
};

const _apiSearchArt = async (
  fullRange = false,
  byPassFilters = false,
  firstSearch = false
) => {
  if (firstSearch) {
    API_SEARCH_ART_COUNT += 1;
  }
  const currentCount = API_SEARCH_ART_COUNT;
  const params = getUrlParams();
  // Make sure that query has a value, defaulting to *
  const query = params.q || "*";
  const page = params.page ? Number(params.page) : 0;

  const hasQuery = Boolean(query && query !== "*");
  const facets = firstSearch ? getFacets(true) : "";
  const filters = byPassFilters ? "" : await getFilters(params.filters);
  const range = params.range ? formatRangeParam(params.range, fullRange) : "";
  // const rangeStep = getRangeStep(window.location.pathname)
  const highlights = Boolean(!hasQuery && !filters && !range)
    ? `&randomHighlights=${randomHighlightsId}`
    : "";

  // const rowsValue = page ? rows * (page + 1) : rows;
  const offset = rows * page;
  const url = `${API_ROOT}${ENDPOINT_ART_SEARCH}?keys=${query}${filters}${range}${facets}${highlights}&lang=${lang}&offset=${offset}&rows=${rows}`;

  return fetch(url, {
    method: "get"
  })
    .then(response => {
      if (currentCount < API_SEARCH_ART_COUNT) {
        throw new Error("apiSearchArt > invalidated search");
      }
      try {
        return response.json();
      } catch (e) {
        return response;
      }
    })
    .catch(error => {
      return null;
    });
};

export const apiSearchArt = async (fullRange = false, byPassFilters = false) =>
  _apiSearchArt(fullRange, byPassFilters, true);

export const apiPaginateArt = async (
  fullRange = false,
  byPassFilters = false
) => _apiSearchArt(fullRange, byPassFilters, false);

export const apiSearchArtByFacet = (
  paramKey,
  facetKey,
  facetValue,
  rows = 1,
  onlyImage = false
) => {
  let paramString = null;
  if (paramKey === paramKeys.filters) {
    let fieldValue = encodeURIComponent(
      `[${facetKey}${facetValue ? ":" + facetValue : ""}]`
    );

    if (onlyImage) {
      fieldValue += ",[has_image:true]";
    }

    paramString = `&${paramKey}=${fieldValue}`;
  } else {
    paramString = `&${paramKey}=${facetKey}${
      facetValue ? ":" + facetValue : ""
    }`;
  }

  const url = `${API_ROOT}${ENDPOINT_ART_SEARCH}?keys=*${paramString}&lang=${lang}&page=0&offset=0&rows=${rows}`;

  return fetch(url, {
    method: "get"
  })
    .then(response => {
      try {
        return response.json();
      } catch (e) {
        console.error("apiSearchArtByFacet > catch", e);
        return response;
      }
    })
    .catch(error => {
      console.error("apiSearchArtByFacet > error", error);
      return null;
    });
};

export const apiSearchPerson = async () => {
  const urls = [
    `${API_ROOT}${ENDPOINT_PERSON_SEARCH}?keys=*&${
      paramKeys.fields
    }=id,name,nationality&sort=name&sort_type=asc&lang=${lang}&offset=0&rows=2000`,
    `${API_ROOT}${ENDPOINT_PERSON_SEARCH}?keys=*&${
      paramKeys.fields
    }=id,name,nationality&sort=name&sort_type=asc&lang=${lang}&offset=2000&rows=2000`
  ];

  return Promise.all(
    urls.map(url =>
      fetch(url, {
        method: "get"
      })
        .then(response => {
          try {
            return response.json();
          } catch (e) {
            console.error("apiSearchPerson > catch", e);
            return response;
          }
        })
        .catch(error => {
          console.error("apiSearchPerson > error", error);
          return null;
        })
    )
  ).then(data => {
    return data.map(d => [...d.items]).flat();
  });
};

export const apiGetArtwork = object_number => {
  return fetch(
    `${API_ROOT}${ENDPOINT_ARTWORK}?object_number=${object_number}&lang=${lang}`,
    {
      method: "get"
    }
  )
    .then(response => {
      try {
        return response.json();
      } catch (e) {
        console.error("apiGetArtwork > catch", e);
        return response;
      }
    })
    .catch(error => {
      console.error("apiGetArtwork > error", error);
      return null;
    });
};

export const apiGetPerson = id => {
  return fetch(`${API_ROOT}${ENDPOINT_PERSON}?id=${id}&lang=${lang}`, {
    method: "get"
  })
    .then(response => {
      try {
        return response.json();
      } catch (e) {
        console.error("apiGetPerson > catch", e);
        return response;
      }
    })
    .catch(error => {
      console.error("apiGetPerson > error", error);
      return null;
    });
};

export const apiGetGeo = async (object_number, kilometers = 5, rows = 100) => {
  const params = new URLSearchParams({
    object_number,
    kilometers,
    lang
  });

  if (rows) {
    params.append("rows", rows);
  }

  try {
    const resp = await fetch(
      `${API_ROOT}${ENDPOINT_GEO}?${params.toString()}`,
      {
        method: "get"
      }
    );
    return await resp.json();
  } catch (error) {
    console.error("apiGetPerson > error", error);
    return null;
  }
};

/* HELPERS */

const formatRangeParam = (range, fullRange) => {
  // Ex. (encoded): &range=[acquisition_date:{1200-01-01T00%3A00%3A00.000Z%3B1800-01-01T00%3A00%3A00.000Z}]
  // Ex. (decoded): &range=[acquisition_date:{1200-01-01T00:00:00.000Z;1800-01-01T00:00:00.000Z}]

  if (fullRange) {
    return `&${
      paramKeys.range
    }=[production_dates_end:{0000-01-01T00:00:00.000Z;${new Date().getUTCFullYear()}-01-01T00:00:00.000Z}]`;
  }

  const fields = range.split("|");
  const formatted = fields.map(
    r => `[${r.split(":")[0]}:{${r.split(":")[1]}}]`
  );
  console.log(formatted);
  const joined = formatted.join(",");
  return `&${paramKeys.range}=${joined}`;
};

const getFacets = unlimitedFacets => {
  if (unlimitedFacets) {
    return `&facetNum=-1&${paramKeys.facets}=${FACETS_UNLIMITED.join(",")}`;
  } else {
    return `&facetNum=500&${paramKeys.facets}=${FACETS_LIMITED.join(",")}`;
  }
};

const getFilter = async filter => {
  /*const k = filter.split(':')[0]
  const v = decodeURIComponent(filter.split(':')[1])
  if(k === filterKeys.colors) {
    const allDataWithColorFacets = await apiSearchArtByFacet(paramKeys.facets, filterKeys.colors)
    if(!allDataWithColorFacets || !allDataWithColorFacets.facets || !allDataWithColorFacets.facets.colors) return null
    const colorFacets = allDataWithColorFacets.facets.colors
    const colors = colorFacets.filter((c, idx) => idx%2 === 0)
    const nearest = fromHex(colors).find(v)
    const hexString = (nearest >= 0 ? '#' + nearest.toString(16).toUpperCase() : false)
    return encodeURIComponent(`[${k}:${hexString}]`)
    // return encodeURIComponent(`[${k}:${v}]`)
  }*/
  return `${encodeURIComponent("[")}${filter}${encodeURIComponent("]")}`;
};

const getFilters = async filters => {
  if (!filters) return "";
  const filtersArray = filters.split("|");
  const filtersFormatted = await Promise.all(
    filtersArray.map(f => getFilter(f))
  );
  return `&filters=${filtersFormatted.join(",")}`;
};
