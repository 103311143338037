export function getUrlParams() {
  const { search } = window.location;
  if (!search.includes("?")) return {};
  let hashes = search.slice(search.indexOf("?") + 1).split("&");
  return hashes.reduce((params, hash) => {
    let [key, val] = hash.split("=");
    return Object.assign(params, { [key]: decodeURIComponent(val) });
  }, {});
}

const _buildPathnameAndSearch = (
  history,
  params,
  preventPush,
  path,
  keepPageIndex,
  mode
) => {
  const search = Object.keys(params)
    .filter(key => params[key])
    .map(key => {
      let value = null;

      // reset page to '0', unless push comes from SearchAutoPaging
      if (key === "page") {
        if (!keepPageIndex) {
          value = 0;
        } else {
          value = params[key];
        }
      } else {
        value = encodeURIComponent(params[key]);
      }

      return `${key}=${value}`;
    })
    .join("&");

  if (preventPush) {
    return `?${search}`;
  }

  const pathname = path ? path : history.location.pathname.split("?")[0];

  history[mode]({
    pathname: pathname,
    search: search
  });
};

export const pushUrlParams = (
  history,
  params,
  preventPush,
  path,
  keepPageIndex = false
) =>
  _buildPathnameAndSearch(
    history,
    params,
    preventPush,
    path,
    keepPageIndex,
    "push"
  );

export const replaceUrlParams = (
  history,
  params,
  preventPush,
  path,
  keepPageIndex = false
) =>
  _buildPathnameAndSearch(
    history,
    params,
    preventPush,
    path,
    keepPageIndex,
    "replace"
  );

export function resetPageIndex(search) {
  const pageParam = "&page=";
  if (!search || !search.includes(pageParam)) return search;

  const params = search.split("&");
  const newSearch = params
    .map(param => {
      const parts = param.split("=");
      const key = parts[0];
      let value = parts[1];
      if (key === "page") {
        value = 0;
      }
      return `${key}=${value}`;
    })
    .join("&");

  return newSearch;
}

export function getUrlParamFieldByKey(
  location,
  param,
  fieldKey,
  urlParams = null
) {
  const currentUrlParams = urlParams || getUrlParams();
  const fields = currentUrlParams[param];

  if (!fields) return fields;
  const fieldValue = fields
    .split("|")
    .filter(f => f.split(":")[0] === fieldKey)
    .map(f => f.split(":")[1])[0];
  return fieldValue ? decodeURIComponent(fieldValue) : null;
}

export function getUrlParamsFieldByKey(
  location,
  param,
  fieldKey,
  urlParams = null
) {
  const currentUrlParams = urlParams || getUrlParams();
  const fields = currentUrlParams[param];

  if (!fields) {
    return fields;
  }

  const fieldValue = fields
    .split("|")
    .filter(f => f.split(":")[0] === fieldKey)
    .map(f => f.split(":")[1])
    .map(decodeURIComponent);

  return fieldValue.length ? fieldValue : null;
}

export function pushSearchParamItem(
  history,
  location,
  param,
  key,
  value,
  preventPush,
  includeCurrentParams = true,
  pathname
) {
  const currentSearchParams = includeCurrentParams ? getUrlParams() : {};
  const currentParam = currentSearchParams[param];

  let newParamKeyValuePair = null;
  if (Array.isArray(key) && Array.isArray(value)) {
    newParamKeyValuePair = key
      .map((k, index) => {
        if (!value[index]) {
          return undefined;
        }
        return `${k}:${encodeURIComponent(value[index])}`;
      })
      .filter(Boolean)
      .join("|");
  } else if (Boolean(key) && Array.isArray(value)) {
    if (value.length) {
      newParamKeyValuePair = value
        .map(v => `${key}:${encodeURIComponent(v)}`)
        .join("|");
    }
  } else if (Boolean(key) && Boolean(value)) {
    newParamKeyValuePair = `${key}:${encodeURIComponent(value)}`;
  }

  let nextParam = null;

  if (!currentParam && newParamKeyValuePair) {
    nextParam = newParamKeyValuePair;
  }

  if (currentParam) {
    var currentParams = currentParam === null ? [] : currentParam.split("|");
    var newParams =
      newParamKeyValuePair === null ? [] : newParamKeyValuePair.split("|");

    var addedNewParams = newParams.filter(n => !currentParams.includes(n));
    var removedCurrentParams = currentParams.filter(
      c => !newParams.includes(c)
    );

    nextParam = [
      ...currentParams.filter(kvPair => {
        const k = kvPair.split(":")[0];
        if (Array.isArray(key) ? key.includes(k) : k === key) {
          return !removedCurrentParams.includes(kvPair);
        }
        return true;
      }),

      ...addedNewParams
    ];

    nextParam = nextParam.join("|");
  }

  const newSearchParams = {
    ...{},
    ...currentSearchParams,
    ...{ [param]: nextParam }
  };

  return pushUrlParams(history, newSearchParams, preventPush, pathname);
}
