export const defaultQuery = "?q=*&page=0";

export const paramKeys = Object.freeze({
  q: "q",
  facets: "facets",
  fields: "fields",
  filters: "filters",
  range: "range",
  rangeStep: "rangeStep"
});

export const filterKeys = Object.freeze({
  collection: "collection",
  colors: "colors",
  content_subject: "content_subject",
  content_person: "content_person",
  craftsman: "craftsman",
  creator_craftsman: "creator_craftsman",
  creator_role: "creator_role",
  current_location_name: "current_location_name",
  has_image: "has_image",
  image_orientation: "image_orientation",
  materials: "materials",
  nationality: "nationality",
  object_names: "object_names",
  on_display: "on_display",
  part_of: "part_of",
  public_domain: "public_domain",
  responsible_department: "responsible_department",
  tags: "tags",
  techniques: "techniques",
  work_status: "work_status",
  medium: "medium",
  // NEW
  creator: "creator",
  artist: "artist",
  role_attributed_to: "role_attributed_to",
  role_earlier_ascribed_to: "role_earlier_ascribed_to",
  role_workshop_of: "role_workshop_of",
  role_follower: "role_follower",
  role_after: "role_after",
  role_school: "role_school",
  role_imitator_of: "role_imitator_of",
  role_copy_after: "role_copy_after",
  role_after_model_by: "role_after_model_by",
  role_publisher: "role_publisher",
  role_printer: "role_printer",
  role_artist: "role_artist",
  creator_gender: "creator_gender",
  creator_nationality: "creator_nationality",
});

export const rangeKeys = Object.freeze({
  acquisition_date: "acquisition_date",
  production_dates_start: "production_dates_start",
  production_dates_end: "production_dates_end"
});

export const filterPersonTypes = [
  {
    name: filterKeys.creator
  },
  {
    name: filterKeys.craftsman
  }
];
