import { Power2, TweenLite, ScrollToPlugin } from "gsap/all";
import { LIB } from "../constants/consts-lib";
import { routes } from "../constants/consts-routes";

export const CREATOR_PRIMARY_ROLES = [
  "tilskrevet",
  "attributed to",
  "kunstner",
  "artist"
];
export const CREATOR_IGNORED_ROLES = ["kunstner", "artist"];

const plugins = [ScrollToPlugin]; // eslint-disable-line no-unused-vars

export const isTouchDevice = () => {
  return Boolean("ontouchstart" in document.documentElement);
};

export const isMobile = () => {
  return window.matchMedia("(max-width: 767px)").matches;
};
export const isSmall = () => {
  return window.matchMedia("(min-width: 768px)").matches;
};
export const isMedium = () => {
  return window.matchMedia("(min-width: 1024px)").matches;
};
export const isLarge = () => {
  return window.matchMedia("(min-width: 1200px)").matches;
};
export const isXLarge = () => {
  return window.matchMedia("(min-width: 1440px)").matches;
};
export const isXXLarge = () => {
  return window.matchMedia("(min-width: 1920px)").matches;
};

export const getImageByWidth = (artwork, widthInPixels = 384) => {
  const { image_iiif_id, image_thumbnail } = artwork;

  return image_iiif_id
    ? `${image_iiif_id}/full/!${widthInPixels},/0/default.jpg`
    : image_thumbnail
    ? image_thumbnail
    : null;
};

export const getThumbnailFromArtwork = (
  artwork,
  width,
  artworkPage = false
) => {
  const imageWidth = width ? width : isMobile() ? 768 : 1024;
  // artworkPage argument was added to allow only artwork Page to load 2048 resolution images
  return getImageByWidth(artwork, artworkPage ? 2048 : imageWidth);
};

export const getImageFromArtwork = artwork => {
  const imageWidth = isXXLarge() ? 3840 : isXLarge() ? 2880 : 2048;
  return getImageByWidth(artwork, imageWidth);
};

export const getViewPortWidth = () => {
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
};

export const getViewPortHeight = () => {
  return Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
};

export const getLanguageCode = () => {
  return window.getLanguageCode();
};

export const isSearchOrFilterRoute = input => {
  const { pathname } = window.location;

  const test = input !== undefined ? input : pathname;

  return (
    test.includes(routes.search.pathname) &&
    !test.includes(routes.artwork.basepath)
  );
};

export const isSearchFilterRoute = () => {
  const { pathname } = window.location;
  return pathname.includes(routes.filter_by_advanced.pathname);
};

export const isArtworkRoute = () => {
  const { pathname } = window.location;
  return pathname.includes(routes.artwork.pathname);
};

export const getRandomId = () => {
  return `${Math.random()
    .toString()
    .slice(2, 7)}`;
};

export const scrollToSearchHeader = () => {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const offsetTop = document.getElementById("header").getBoundingClientRect()
    .top;
  TweenLite.to(window, 0.5, {
    scrollTo: scrollTop + offsetTop,
    ease: Power2.easeInOut
  });
};

export const scrollToY = (containerElement, yPos, time) => {
  TweenLite.to(containerElement, time, {
    scrollTo: yPos,
    ease: Power2.easeInOut
  });
};

export const scrollToId = id => {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const elem = document.getElementById(id);
  if (!elem) return;
  const offsetTop = elem.getBoundingClientRect().top;
  TweenLite.to(window, 0.5, {
    scrollTo: scrollTop + offsetTop,
    ease: Power2.easeInOut
  });
};

export const sortByObjectNumberPart = (a, b) => {
  if (a.includes("/") && b.includes("/")) {
    return Number(a.split("/")[1]) - Number(b.split("/")[1]);
  } else {
    return a - b;
  }
};

export const sortArtworksByObjectNumberPart = (a, b) => {
  if (a.object_number.includes("/") && b.object_number.includes("/")) {
    return (
      Number(a.object_number.split("/")[1].split(" ")[0]) -
      Number(b.object_number.split("/")[1].split(" ")[0])
    );
  } else {
    return a.object_number - b.object_number;
  }
};

export const getYearFromDate = dateString => {
  if (!dateString) return "";
  return new Date(dateString).getUTCFullYear();
};

export const getDateString = dateString => {
  if (!dateString) return "";
  return new Date(dateString).toLocaleDateString(getLanguageCode());
};

export const thousandSeparator = x => {
  if (!x) return x;
  const parts = x.toString().split(",");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return parts.join(",");
};

export const getArtworkTitlePeriodCreator = artwork => {
  const titlePrimary = getArtworkTitlePrimary(artwork);
  const period = getProductionPeriod(artwork);
  const creator = getCreatorNamesAsString(artwork);
  return (
    (titlePrimary ? titlePrimary : "") +
    (period ? (titlePrimary ? ", " : "") + period : "") +
    (creator ? ", " + creator : "")
  );
};

export const getArtworkTitlePrimary = (artwork, showType, truncate) => {
  if (!artwork) {
    return null;
  }
  const titles = getArtworkTitlesLocal(artwork);
  if (!titles || titles.length === 0) {
    return null;
  }
  const titlesLocal = titles.filter(t => t.type !== "PREVIOUS");
  if (titlesLocal && titlesLocal[0]) {
    let title = titlesLocal[0].title;

    if (truncate && title.length > 200) {
      title = truncateText(title);
    }

    return showType && titlesLocal[0].type
      ? `${title} (${titlesLocal[0].type})`
      : title;
  } else {
    return LIB.ARTWORK.unknown;
  }
};

export const getArtworkTitles = artwork => {
  const { titles } = artwork;
  if (!titles || titles.length === 0) {
    return [];
  }
  return titles.filter(obj => !!obj && !!obj.title);
};

export const getArtworkTitlesLocal = artwork => {
  const { titles } = artwork;
  if (!titles || titles.length === 0) {
    return [];
  }
  const titlesLocal = titles.filter(t => t.title);
  if (titlesLocal) {
    return titlesLocal;
  }
  return [];
};

export const getArtworkTitlesTranslated = artwork => {
  const { titles } = artwork;
  if (!titles || titles.length === 0) {
    return [];
  }
  const titlesTranslated = titles.filter(t => t.translation);
  if (titlesTranslated) {
    return titlesTranslated;
  }
  return [];
};

export const getProductionPlaces = artwork => {
  const { production } = artwork;
  if (!production || production.length === 0) return null;
  const placesArray = production.filter(p => p.place).map(p => p.place);
  return placesArray.length > 0 ? placesArray.join(", ") : null;
};

export const getProductionPeriod = artwork => {
  const { production_date } = artwork;
  if (!production_date || production_date.length === 0) return null;
  const hasStart = production_date.filter(d => d.start).length > 0;
  // return hasPeriod ? production_date.filter(d => d.period).map(d => d.period.split('-').join(' – ')) : null
  if (hasStart) {
    return production_date
      .filter(d => d.start)
      .map(d => {
        const startYear = d.start.split("-")[0];
        const endYear = (d.end || "").split("-")[0];

        if (startYear === endYear || !endYear) {
          return startYear;
        }

        return `${startYear} – ${endYear}`;
      });
  } else return null;
};

export const getLocale = () =>
  window.location.pathname.startsWith("/en") ? "en-US" : "da-DK";

const formatExhibitionDate = d =>
  new Intl.DateTimeFormat(getLocale(), {
    year: "numeric",
    month: "short",
    formatMatcher: "best fit"
  }).format(d);

export const getExhibitions = artwork => {
  const { exhibitions } = artwork;
  if (!exhibitions || !exhibitions.length) {
    return null;
  }

  return exhibitions
    .sort((a, b) => {
      if (a.date_start && b.date_start) {
        return new Date(a.date_start) < new Date(b.date_start) ? 1 : -1;
      } else if (a.date_start && !b.date_start) {
        return -1;
      } else if (!a.date_start && b.date_start) {
        return 1;
      } else {
        return a.exhibition
          .toLowerCase()
          .localeCompare(b.exhibition.toLowerCase());
      }
    })
    .map(({ date_start, date_end, ...rest }) => {
      const dateStart = date_start ? new Date(date_start) : undefined;
      const dateEnd = date_end ? new Date(date_end) : undefined;

      const date = Boolean(dateStart || dateEnd)
        ? `${formatExhibitionDate(dateStart)} – ${formatExhibitionDate(
            dateEnd
          )}`
        : undefined;

      return {
        ...rest,
        date
      };
    });
};

export const formatCreatorName = str => {
  if (!str) {
    return "";
  }

  const parts = str
    .split(",")
    .map(s => s.trim())
    .filter(s => Boolean(s));

  return parts.length >= 2 ? [parts[1], parts[0]].join(" ") : str;
};

export const getCreatorName = (creator, showRole) => {
  if (!creator) {
    return;
  }
  const { name, role } = creator;
  return showRole && role && !CREATOR_IGNORED_ROLES.includes(role.toLowerCase())
    ? `${name} (${role.toLowerCase()})`
    : name;
};

export const getCreators = (artwork, filterSecondary) => {
  if (!artwork) {
    return [
      {
        name: LIB.ARTWORK.unknown
      }
    ];
  }

  const { production } = artwork;

  if (!production || !production.length) {
    return [
      {
        name: LIB.ARTWORK.unknown
      }
    ];
  }

  const objs = filterSecondary
    ? production.filter(
        o =>
          o.creator_role === undefined ||
          CREATOR_PRIMARY_ROLES.includes(o.creator_role.toLowerCase())
      )
    : production;

  if (!objs || !objs.length) {
    return [
      {
        name: LIB.ARTWORK.unknown
      }
    ];
  }

  return objs.map(obj => {
    let name = LIB.ARTWORK.unknown;

    if (obj.creator) {
      name = formatCreatorName(obj.creator);
    } else if (obj.creator_forename && obj.creator_surname) {
      name = `${obj.creator_forename} ${obj.creator_surname}`;
    }

    return {
      name,
      role: obj.creator_role ? obj.creator_role.toLowerCase() : null,
      lifeSpan: obj ? getCreatorLifeSpan(obj) || "" : "",
      creator_gender: obj.creator_gender,
      creator_nationality: obj.creator_nationality
    };
  });
};

export const getCreatorNames = (artwork, filterSecondary, showRole) => {
  const creators = getCreators(artwork, filterSecondary);

  return creators && creators.length
    ? creators.map(creator => getCreatorName(creator, showRole))
    : LIB.ARTWORK.unknown;
};

export const getCreatorNamesAsString = (artwork, filterSecondary, showRole) => {
  const names = getCreatorNames(artwork, filterSecondary, showRole);

  return Array.isArray(names) ? names.join(", ") : names;
};

export const getCreditLine = artwork => {
  if (!artwork) {
    return;
  }

  const { credit_line } = artwork;

  if (credit_line && credit_line.length) {
    return credit_line.map(str => str.replace("© ", "")).join(", ");
  } else {
    const creators = getCreators(artwork, true).filter(
      ({ name }) =>
        !["ukendt", "ubekendt", "unknown"].includes(name.toLowerCase())
    );

    if (creators.length) {
      return `${creators.map(c => c.name).join(", ")} / VISDA`;
    }
  }
};

export const getPublicDomain = artwork => {
  if (!artwork) {
    return { publicDomain: undefined, text: undefined };
  }

  const { public_domain } = artwork;

  if (public_domain === undefined) {
    return { publicDomain: undefined, text: undefined };
  }

  const text = public_domain
    ? LIB.ARTWORK.artwork_has_no_copyright
    : getCreditLine(artwork) || LIB.ARTWORK.artwork_has_copyright;

  return { publicDomain: public_domain, text };
};

export const getProductionNotes = artwork => {
  const { production_dates_notes } = artwork;
  if (!production_dates_notes || production_dates_notes.length === 0) {
    return null;
  }
  return production_dates_notes.map(item => item);
};

export const getCreatorLifeSpan = person => {
  const { creator_date_of_birth, creator_date_of_death } = person;
  const b = creator_date_of_birth ? getYearFromDate(creator_date_of_birth) : "";
  const d = creator_date_of_death ? getYearFromDate(creator_date_of_death) : "";
  if (b === "" && d === "") return null;
  return `${b} – ${d}`;
};

function buildDimension(allDimensions, part) {
  try {
    const dimensions = allDimensions.filter(dimension => {
      if (part === undefined) {
        return dimension.part === undefined;
      }
      return dimension.part && dimension.part.toLowerCase() === part;
    });

    if (dimensions.length > 3) {
      throw new Error("too many dimensions!");
    }

    const values = dimensions
      .map(
        ({ value, unit, type }) =>
          `${value}${unit} ${type ? ` (${type[0]})` : ""}`
      )
      .filter(Boolean)
      .join(" x ");

    const type = Boolean(part) && part !== "netto" ? `(${part})` : undefined;

    return [values, type].filter(Boolean).join(" ");
  } catch (e) {
    return;
  }
}

export const getArtworkDimensions = artwork => {
  const { dimensions } = artwork;

  if (!dimensions || dimensions.length === 0) {
    return LIB.ARTWORK.unknown;
  }

  const parts = [...new Set(dimensions.map(d => d.part))]
    .map(d => (d ? d.toLowerCase() : undefined))
    .filter(part => part !== "brutto");

  if (parts.length === 0) {
    return LIB.ARTWORK.unknown;
  }

  return (
    [
      ...parts
        .filter(part => part === "netto")
        .map(part => buildDimension(dimensions, part)),
      ...parts
        .filter(part => part !== "netto")
        .map(part => buildDimension(dimensions, part))
    ]
      .filter(Boolean)
      .join(", ") || LIB.ARTWORK.unknown
  );
};

export const getArtworkTechniques = artwork => {
  const { techniques } = artwork;
  if (!techniques || techniques.length === 0) return null;
  return techniques && techniques.map(t => t).join(", ");
};

export const setLockedViewport = bool => {
  if (bool) {
    document.body.style.position = "fixed";
    document.body.style.height = "100vh";
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.position = "";
    document.body.style.height = "";
    document.body.style.overflow = "";
  }
};

export function truncateText(text, max = 200, truncation = "...") {
  if (!text || text.length < max) {
    return text;
  }
  return text.slice(0, max) + truncation;
}
