import { getLanguageCode } from "../services/service-utils";

const lang = getLanguageCode();

const da = Object.freeze({
  are_you_sure: "Er du sikker?",
  by_ticket: "Køb billet",
  close: "Luk",
  collapse: "Luk",
  cta_share: "Del",
  error_general: "Ups! Noget gik galt...",
  expand: "Fold ud",
  found_containing_work: "fundet med søgningen",
  filters: "Filtre",
  hide: "Skjul",
  loading: "Henter",
  next: "Næste",
  no_artists_found: "Ingen kunstere fundet",
  no_artworks_added: "Ingen kunstværker tilføjet",
  not_on_display: "Ikke udstillet",
  previous: "Forrige",
  read_less: "Læs mindre",
  read_more: "Læs mere",
  search_in_artworks_intro: "Fx kat, Hammershøi, natur",
  search_input_placeholder: "Hvad leder du efter?",
  search_input_placeholder_themes: "Søg i temaer",
  suggestion_text: "mente du",
  search_layout_grid: "Stor gittervisning",
  search_layout_masonry: "Lille gittervisning",
  search_layout_list: "Listevisning",
  back_to_search_results: "Tilbage til søgeresultater",
  work: "værk",
  zoom_in: "Zoom ind",
  zoom_out: "Zoom ud",
  worksInProximity: "Værker i samme område",
  noWorksInProximity: "Ingen værker i samme område",
  works: "værker",
  within: "indenfor",
  seeDKCard: "Se kort",
  fileSuffix: "-fil",
  GENDER: {
    MALE: "Mand",
    FEMALE: "Kvinde"
  },
  PAGE_TITLES: {
    home: "SMK OPEN",
    search: "Søg",
    filter_by_advanced: "Filtre",
    artwork: "",
    persons: "Søg i kunstnere",
    person: "",
    about: "Om SMK OPEN",
    my_list: "Min liste",
    shared_list: "Delt liste",
    theme: "Tema",
    themes: "Temaer"
  },
  INTRO: {
    title: "Udforsk tusindvis af kunstværker fra SMK's samling",
    description: "til fri brug i dit eget projekt",
    advancedSearchText1: "Prøv vores mange",
    advancedSearchText2: "søgemuligheder, fx."
  },
  FILTERS: {
    results_title: "Resultater",
    all_colors: "Vælg farve",
    all_years: "Vælg årstal",
    all_artists: "Vælg kunstner",
    all_filters: "Udforsk detaljer",
    clear_filters: "Ryd filtre",
    nav_artwork: "Værk",
    nav_creator: "Kunstner",
    nav_period: "Periode",
    has_image: "Med foto",
    has_3d_file: "Med 3D",
    image_hq: "Med stort foto",
    no_facets_available: "Ingen filtre tilgængelige",
    on_display: "Udstillet på SMK",
    public_domain: "Til fri brug",
    search: "Søg",
    groups: {
      work: "Værk",
      motive: "Motiv",
      creator: "Kunstner/Producent",
      role: "Rolle"
    },
    facets: {
      acquisition_year: "Erhvervelsesår",
      work_status: "Værkstatus",
      collection: "Samling",
      content_person: "Portrætteret person",
      content_subject: "Topografisk motiv",
      literary_reference: "Litterært forlæg",
      materials: "Materiale",
      medium: "Medium",
      name: "Kunstner",
      nationality: "Nationalitet",
      object_names: "Værktype",
      role: "Rolle",
      techniques: "Teknik",
      period: "Værkdatering",
      year_from: "1000",
      year_to: new Date().getUTCFullYear(),
      colors: "Farve",
      // NEW
      creator: "Alle personer",
      artist: "Kunstner",
      role_attributed_to: "Tilskrevet",
      role_earlier_ascribed_to: "Tidligere tilskrevet",
      role_workshop_of: "Værksted",
      role_follower: "Efterfølger",
      role_after: "Efter",
      role_school: "Skole",
      role_imitator_of: "Stil",
      role_copy_after: "Kopi efter",
      role_after_model_by: "Efter forlæg af",
      role_publisher: "Udgiver",
      role_printer: "Trykker",
      role_artist: "Forfatter",
      creator_nationality: "Nationalitet",
      creator_gender: "Køn",
      production_dates_end: "Datering",
      acquisition_date: "Erhvervelsesår"
    },
    descriptions: {
      object_names: "Klassifikation af værket bestemt af dets form, funktion eller type.",
      production_dates_end: "Tidspunktet for et værks tilblivelse. For fx grafik opereres også med udførelses- og udgivelsesdateringer.",
      materials: "Det materiale værket består af. Historisk set har man skelnet mellem “materiale” som det bærende (under)lag, fx ”lærred” og “medium” som det bårne (farve)lag, fx ”olie”.",
      medium: "De bårne materialer, som typisk er de påførte lag, fx ”blyant”, ”blæk” eller ”olie”.",
      techniques: "Den teknik, der er anvendt i skabelsen af et værk. Typisk en sammenskrivning af sammenhængen mellem materiale og medium, fx ”Olie på lærred”.",
      acquisition_date: "Det år et værk er tilgået museets samling.",
      content_person: "En person afbildet i et kunstværk.",
      content_subject: "Et sted, der er gengivet i et kunstværk.",
      colors: "Søgning på baggrund af kunstværkernes farver.",
      creator: "Foruden kunstnere også forfattere, trykkere og udgivere, der har medvirket til kunstværkets skabelse.",
      artist: "Person, som regnes for ophav til et originalt værk i samlingen.",
      creator_nationality: "Kunstnere og andre producenters nationalitet.",
      creator_gender: "Kunstnere og andre producenters køn.",
      role_attributed_to: "Kunstner, som med stor sandsynlighed – men uden fuld sikkerhed – er ophav til et eller flere værker.",
      role_earlier_ascribed_to: "Kunstner, der tidligere blev vurderet som ophav til et eller flere kunstværker.",
      role_workshop_of: "Kunstner hvis elever eller assistenter menes at have udført et eller flere kunstværker.",
      role_follower: "En, typisk ubekendt, kunstner hvis stil er stærkt påvirket af en anden kunstners stil.",
      role_after: "Kunstner, der har skabt et kunstværk baseret på en anden kunstners værk.",
      role_school: "Kunstner, som har påvirket skaberne af et eller flere kunstværker.",
      role_imitator_of: "Kunstner, hvis stil ligger til grund for et eller flere kunstværker af andre kunstnere.",
      role_copy_after: "Kunstner, hvis kunstværk er en gentagelse af et andet kunstværk udført af en anden kunstner.",
      role_after_model_by: "Kunstner hvis kunstværk har dannet forlæg for et andet værk.",
      role_publisher: "Udgiveren af en publikation eller et grafisk værk, fx et forlag eller kunstneren selv.",
      role_printer: "Trykkeren af et grafisk værk, fx et trykkeri, en navngiven trykker eller kunstneren selv.",
      role_artist: "Forfatteren til en publikation, typisk et bogværk, hvori et eller flere grafiske værker indgår."
    }
  },
  SECTIONS: {
    top: "Top",
    related: "Relaterede værker",
    audiovideo: "Audio/Video",
    info: "Info om værket",
    text: "Værktekst",
    references: "Referencer",
    notes: "Bemærkninger",
    exhibitions: "Udstillinger",
    questions: "Stil et spørgsmål"
  },
  ARTWORK: {
    about_the_artwork: "Info om værket",
    artwork_by_same_artist: "Andre værker af ",
    artwork_by_same_artist_all: "Alle værker af ",
    artwork_has_copyright: "Beskyttet af ophavsret",
    artwork_has_no_copyright: "Til fri brug",
    artwork_data: "Værkinfo",
    artwork_of_same_object: "Værker med samme motiv",
    artwork_of_same_person: "Værker med samme person",
    artwork_of_same_color: "Værker med samme farve",
    artwork_of_similarity: "Lignende værker",
    acquisition: "Erhvervelse",
    acquisition_date_precision: "Erhvervelsesår",
    collection: "Samling",
    colors: "Farver",
    copy_link: "Kopiér link",
    copy_number: "Eksemplar nr.",
    content_description: "Beskrivelse",
    creator: "Kunstner/producent",
    credit_line: "Signatur",
    current_owner: "Nuværende ejer",
    dating: "Datering og sted",
    details: "Detaljer",
    date_of_birth: "f",
    date_of_death: "d",
    dimensions: "Dimensioner",
    distinguishing_features: "Særlige kendetegn",
    documentation: "Referencer",
    download_hires: "Fuld størrelse",
    download_lores: "Lille version",
    download_text: "Her kan du downloade billeder af kunstværket. Hvis kunstværket er i Public Domain, kan du bruge billedet helt som du har lyst. Hvis kunstværket er omfattet af ophavsret, må du kun bruge billedet til private, ikke-kommercielle formål.",
    edition: "Udgave",
    exhibitions: "Udstillinger",
    exhibited_now: "Udstillet i",
    feedback: "Spørgsmål",
    feedback_info: "Har du et spørgsmål, eller ligger du inde med yderligere viden om dette kunstværk? Så send en mail til",
    info: "Info",
    inscriptions: "Inskriptioner",
    inscription_type: "Type",
    inscription_description: "Beskrivelse",
    labels: "Værktekst",
    list_add_to: "Føj til min liste",
    list_added_to: "Føjet til min liste",
    materials: "Materiale",
    medium: "Medium",
    more_info: "Mere info",
    no_3d: "Der findes ingen 3D information til dette værk",
    no_data: "Der findes ingen information på dette værk",
    no_image: "Billede på vej",
    no_image_excerpt: "Vi tager løbende nye billeder af kunsten. Lige nu har vi affotograferet [] kunstværker. Vores målsætning er, at hele samlingen bliver digitalt tilgængelig for dig her på SMK Open.",
    notes: "Bemærkninger",
    number_of_parts: "Antal dele",
    object_number: "Inventarnummer",
    object_history_note: "Proveniens",
    object_names: "Værktype",
    original: "Original",
    part_of: "En del af værket",
    parts: "Dele af værket",
    period: "Datering",
    print_state: "Stadie",
    production_date: "Datering",
    production_date_notes: "Dateringsnoter",
    production_place: "Sted",
    production_period: "Periode",
    production_notes: "Dateringsnote",
    responsible_department: "Samling",
    source: "Kilde",
    techniques: "Teknik",
    section_techniques: "Fysiske egenskaber",
    tags: "Tags",
    title: "Titel",
    translated: "Oversættelser",
    unknown: "Ukendt",
    view_3d: "3D",
    view_parts: "Værkdele",
    view_download: "Download",
    view_iiif: "Zoom",
    view_image: "Værk",
    view_map: "Kort",
    view_media: "Audio/video",
    view_timeline: "Tidslinie",
    wiki_excerpt: "Uddrag",
    wiki_depicts: "Motiv",
    wiki_type: "Information fra Wiki data",
    work_status: "Relationer"
  },
  ARTWORK_ACTIONS: {
    zoom: "Zoom",
    download: "Download",
    downloadPopover: {
      publicDomain: {
        title: "Materiale til fri brug",
        excerpt: "Her kan du downloade billeder m.m. af kunstværket. Værket er udenfor ophavsret, og du må derfor bruge materialet helt som du har lyst."
      },
      copyright: {
        title: "Materiale under ophavsret",
        excerpt: "Her kan du downloade billeder m.m. af kunstværket. Værket er under ophavsret, og du må derfor kun bruge materialet til private, ikke-kommercielle formål."
      },
      moreInfoLink: "https://www.smk.dk/section/brug-af-museets-materiale/",
      threeDExerpt: "Vi samarbejder med Scan the World – 3D-scannede kulturelle artefakter til fysisk 3D-print"
    },
    threeD: "Se værker i 3D",
    share: "Del",
    sharePopover: {
      copyLink: {
        title: "Del dette værk",
        excerpt: "Link til denne side:",
        action: "Kopiér URL"
      },
      iiif: {
        title: "IIIF-manifest",
        excerpt: "Et IIIF-manifest er et dataobjekt, der indeholder al information relateret til et digitalt billede, inklusive selve billedet.",
        action: "Gå til IIIF-manifest"
      }
    },
    favoriteAdd: "Tilføj til dine favoritter",
    favoriteRemove: "Fjern fra dine favoritter",
    searchColor: "Start en ny søgning med denne farve: []"
  },
  ARTWORK_RELATED: {
    all: "Alle",
    sameArtist: "Samme kunstner",
    samePerson: "Samme portrætterede person",
    sameObject: "Samme topografiske motiv",
    similar: "Lignende værker",
    related: "Udvalgte",
    proximity: "Samme område"
  },
  ARTWORK_RELATED_LINK: {
    sameArtist: "Alle værker af []",
    samePerson: "Alle værker portrætterende []",
    sameObject: "Alle værker med [] som motiv"
  },
  MY_LIST: {
    input_list_title: "Indtast titel",
    list_clear: "Ryd liste",
    list_edit: "Rediger",
    list_edit_done: "Færdig",
    list_share: "Del"
  },
  THEME: {
    scroll_to_continue: "Scroll ned for at fortsætte"
  },
  COOKIES: {
    link: "https://www.smk.dk/politikker/",
    link_text: "Læs vores politikker",
    message: "Vi bruger cookies til at huske dine indstillinger og til statistik"
  }
});

const en = Object.freeze({
  are_you_sure: "Are you sure?",
  by_ticket: "Buy ticket",
  collapse: "Close",
  close: "Close",
  cta_share: "Share",
  error_general: "Something went wrong...",
  expand: "Expand",
  found_containing_work: "found containing",
  filters: "Filters",
  hide: "Hide",
  loading: "Loading",
  next: "Next",
  no_artists_found: "No artists found",
  no_artworks_added: "No artworks added",
  not_on_display: "Not on display",
  previous: "Previous",
  read_less: "Read less",
  read_more: "Read more",
  search_in_artworks_intro: "E.g. cat, Hammershøi, nature",
  search_input_placeholder: "What are you looking for?",
  search_input_placeholder_themes: "Search themes",
  suggestion_text: "did you mean",
  search_layout_grid: "Display large grid",
  search_layout_masonry: "Display small grid",
  search_layout_list: "Display list",
  view: "Back to search results",
  work: "artwork",
  works: "artworks",
  zoom_in: "Zoom in",
  zoom_out: "Zoom out",
  worksInProximity: "Artworks in same area",
  noWorksInProximity: "No artworks in same area",
  within: "within",
  seeDKCard: "See map",
  fileSuffix: " file",
  GENDER: {
    MALE: "Male",
    FEMALE: "Female"
  },
  PAGE_TITLES: {
    home: "SMK OPEN",
    search: "Search",
    filter_by_color: "Choose colour",
    filter_by_year: "Search by year",
    filter_by_person: "Find artist",
    filter_by_advanced: "Filters",
    artwork: "",
    persons: "Search in persons",
    person: "",
    about: "About SMK OPEN",
    my_list: "My list",
    shared_list: "Shared list",
    theme: "Theme",
    themes: "Themes"
  },
  INTRO: {
    title: "Explore thousands of artworks from SMK’s collection",
    description: "For free use in your own project"
  },
  FILTERS: {
    results_title: "Results",
    all_colors: "Select color",
    all_years: "Select years",
    all_artists: "Select artist",
    all_filters: "Explore details",
    clear_filters: "Clear filters",
    nav_artwork: "Artwork",
    nav_creator: "Artist",
    nav_period: "Period",
    has_image: "With photo",
    has_3d_file: "With 3D",
    image_hq: "With large photo",
    no_facets_available: "No filters available",
    on_display: "On display at SMK",
    public_domain: "Free to use",
    search: "Search",
    groups: {
      work: "Work",
      motive: "Subject",
      creator: "Artist/Maker",
      role: "Role"
    },
    facets: {
      acquisition_year: "Acquisition date",
      work_status: "Artwork status",
      collection: "Collection",
      content_person: "Portrait of",
      content_subject: "Place depicted",
      literary_reference: "Literary reference",
      materials: "Material",
      medium: "Medium",
      name: "Artist",
      nationality: "Nationality",
      object_names: "Work type",
      role: "Role",
      techniques: "Techniques",
      period: "Period",
      year_from: "1000",
      year_to: new Date().getUTCFullYear(),
      colors: "Color",
      // NEW
      creator: "All persons",
      artist: "Artist",
      role_attributed_to: "Attributed to",
      role_earlier_ascribed_to: "Earlier ascribed to",
      role_workshop_of: "Workshop of",
      role_follower: "Follower of",
      role_after: "After",
      role_school: "School of",
      role_imitator_of: "Imitator of",
      role_copy_after: "Copy after",
      role_after_model_by: "After model by",
      role_publisher: "Publisher",
      role_printer: "Printer",
      role_artist: "Author",
      creator_nationality: "Nationality",
      creator_gender: "Gender",
      production_dates_end: "Date",
      acquisition_date: "Acqusition date"
    },
    descriptions: {
      object_names: "Classification of the artwork based on its form, function, or type.",
      production_dates_end: "The time of the creation of an artwork. For printmaking we also use official dates of execution or publication.",
      materials: "The materials that an artwork consists of. Historically, there’s been a distinction between “material” as supporting material, e.g. “canvas”, and “medium” as applied material substance, e.g. “oil”.",
      medium: "The applied material, e.g. “pencil”, “ink”, “oil”.",
      techniques: "The technique used in the creation of an artwork. Often a merging of material and medium, e.g. “Oil on canvas”.",
      acquisition_date: "The year an artwork was added to the museum’s collection.",
      content_person: "A person portrayed in an artwork.",
      content_subject: "A place portrayed in an artwork.",
      colors: "Search based on the colors of the artwork.",
      creator: "Artists but also authors, printers, and publishers who have contributed to the creation of an artwork.",
      artist: "Person believed to be the creator of an original artwork in the collection.",
      creator_nationality: "The nationality of artists and other producers.",
      creator_gender: "The gender of artists and other producers.",
      role_attributed_to: "Artist highly likely – but not certain – to be the creator of one or more artworks.",
      role_earlier_ascribed_to: "Artist who has historically been considered the creator of one or more artworks.",
      role_workshop_of: "Artist whose students or assistants are believed to have created one or more artworks.",
      role_follower: "A, typically unknown, artist whose style is strongly influenced by the style of another artist.",
      role_after: "Artist who has created an artwork based on the work of another artist.",
      role_school: "Artist who has influenced the creators of one or more artworks.",
      role_imitator_of: "Artist whose style forms the basis for one or more artworks by other artists.",
      role_copy_after: "Artist whose artwork is a repetition of another artwork created by a different artist.",
      role_after_model_by: "Artist whose artwork has been the basis of another artwork.",
      role_publisher: "The publisher of a publication or a printed artwork, e.g. a publishing house, or the artist in question.",
      role_printer: "The printer of a printed artwork, e.g. a printing house, a professional printer, or the artist in question.",
      role_artist: "The author of a publication, typically a book, in which one or more printed artworks are included."
    }
  },
  SECTIONS: {
    top: "Top",
    related: "Related artworks",
    audiovideo: "Audio/Video",
    info: "About the artwork",
    text: "Read about the artwork",
    references: "References",
    notes: "Comments",
    exhibitions: "Exhibitions",
    questions: "Ask a question"
  },
  ARTWORK: {
    about_the_artwork: "About the artwork",
    artwork_by_same_artist: "Other artworks by ",
    artwork_by_same_artist_all: "All artworks by ",
    artwork_has_copyright: "Protected by copyright",
    artwork_has_no_copyright: "Free to use",
    artwork_data: "Artwork info",
    artwork_of_same_object: "Artworks depicting same object",
    artwork_of_same_person: "Artworks depicting same person",
    artwork_of_same_color: "Artworks with same color",
    artwork_of_similarity: "Similar artworks",
    acquisition: "Acquisition",
    acquisition_date_precision: "Acqusition date",
    collection: "Collection",
    content_description: "Description",
    colors: "Colors",
    copy_link: "Copy link",
    copy_number: "Copy no.",
    creator: "Artist/maker",
    credit_line: "Credit line",
    current_owner: "Current owner",
    dating: "Date/Place",
    details: "Details",
    date_of_birth: "b",
    date_of_death: "d",
    dimensions: "Dimensions",
    distinguishing_features: "Distinguishing features",
    documentation: "References",
    download_hires: "Full size",
    download_lores: "Small size",
    download_text: "Download photos of the artwork. If the artwork is in the Public Domain, you can use the image for whatever you like. If the artwork is in copyright, you can only use the images for private, non-commercial purposes.",
    edition: "Edition",
    exhibitions: "Exhibitions",
    exhibited_now: "On display in",
    feedback: "Questions",
    feedback_info: "Do you have a question about this artwork, or additional information to share? Please send an email to",
    info: "Info",
    inscriptions: "Inscriptions",
    inscription_type: "Type",
    inscription_description: "Description",
    labels: "Read about the artwork",
    list_add_to: "Add to my list",
    list_added_to: "Added to my list",
    materials: "Materials",
    medium: "Medium",
    more_info: "More info",
    no_3d: "No 3D available for this artwork",
    no_data: "No information exist on this artwork",
    no_image: "Image on the way",
    no_image_excerpt: "We are constantly adding new photos of the art. Currently we have photographed [] artworks. Our goal is to make the entire collection digitally available to you here at SMK Open",
    notes: "Comments",
    number_of_parts: "Number of parts",
    object_number: "Inventory number",
    object_history_note: "Provenance",
    object_names: "Work type",
    original: "Original",
    part_of: "Part of",
    parts: "Parts",
    period: "Period",
    print_state: "Stadie",
    production_date: "Production date",
    production_date_notes: "Production date comments",
    production_place: "Place",
    production_period: "Period",
    production_notes: "Date note",
    responsible_department: "Collection",
    section_techniques: "Physical properties",
    source: "Source",
    techniques: "Techniques",
    tags: "Tags",
    title: "Title",
    translated: "Translations",
    unknown: "Unknown",
    view_3d: "3D",
    view_parts: "Parts",
    view_download: "Download",
    view_iiif: "Zoom",
    view_image: "Artwork",
    view_map: "Map",
    view_media: "Media",
    view_timeline: "Timeline",
    wiki_excerpt: "Excerpt",
    wiki_depicts: "Depicts",
    wiki_type: "Information from Wiki data",
    work_status: "Relations"
  },
  ARTWORK_ACTIONS: {
    zoom: "Zoom",
    download: "Download",
    downloadPopover: {
      publicDomain: {
        title: "Material for free use",
        excerpt: "Download photos etc. of the artwork. The artwork is in the Public Domain and you can use the material in any way you choose."
      },
      copyright: {
        title: "Material under copyright",
        excerpt: "Download photos etc. of the artwork. The artwork is under copyright and you can only use the material for private, non-commercial purposes."
      },
      moreInfoLink: "https://www.smk.dk/en/section/use-of-smk-material/",
      threeDExerpt: "We collaborate with Scan the World – 3D scanned cultural artefacts for physical 3D printing"
    },
    threeD: "Watch artwork in 3D",
    share: "Share",
    sharePopover: {
      copyLink: {
        title: "Share this work",
        excerpt: "Link to this page:",
        action: "Copy URL"
      },
      iiif: {
        title: "IIIF manifest",
        excerpt: "A IIIF manifest is a data package that contains all the information related to a particular digital image, including the image itself.",
        action: "Go to IIIF manifest"
      }
    },
    favoriteAdd: "Add to your favorites",
    favoriteRemove: "Remove from your favorites",
    searchColor: "Start a new search with this color: []"
  },
  ARTWORK_RELATED: {
    all: "All",
    sameArtist: "Same artist",
    samePerson: "Same portrayed person",
    sameObject: "Same topographic motive",
    similar: "Similar artworks",
    related: "Curated",
    proximity: "Same area"
  },
  ARTWORK_RELATED_LINK: {
    sameArtist: "All artworks by []",
    samePerson: "All artworks portraying []",
    sameObject: "All artworks with [] as motive"
  },
  MY_LIST: {
    input_list_title: "Input title",
    list_clear: "Clear list",
    list_edit: "Edit",
    list_edit_done: "Done",
    list_share: "Share"
  },
  THEME: {
    scroll_to_continue: "Scroll to continue"
  },
  COOKIES: {
    link: "https://www.smk.dk/en/policies/",
    link_text: "Read our policies",
    message: "We uses cookies to remember your preferences and for statistics"
  }
});

export const LIB = lang === "da" ? da : en;
