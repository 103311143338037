import { cookies } from "../constants/consts-cookies";
import { getCookie, setCookie } from "./service-cookies";

export const setMyListCookie = listArray => {
  const exdays = 1095;
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = `${cookies.my_list}=${
    listArray ? listArray.join(",") : ""
  };${expires};path=/`;
};

export const getMyList = () => {
  const cookieArray = document.cookie.split(";");
  const list = cookieArray
    .filter(cookie => {
      while (cookie.charAt(0) === " ") cookie = cookie.substring(1);
      return cookie.split("=")[0] === cookies.my_list;
    })
    .map(cookie => {
      while (cookie.charAt(0) === " ") cookie = cookie.substring(1);
      return cookie.split("=")[1];
    })[0];

  if (list) {
    return list.split(",");
  } else {
    return [];
  }
};

export const getMyListTitle = () => {
  return getCookie(cookies.my_list_title);
};

export const setMyListTitle = title => {
  return setCookie(cookies.my_list_title, title);
};

export const isOnMyList = object_number => {
  const list = getMyList();
  const matching = list.filter(item => item === object_number);
  return Boolean(matching.length > 0);
};

export const addToList = object_number => {
  const list = getMyList();
  const alreadyAdded = list ? list.some(id => id === object_number) : false;
  if (!alreadyAdded) {
    list.push(object_number);
    setMyListCookie(list);
  }
  return list;
};

export const removeFromMyList = object_number => {
  const list = getMyList();
  const newList = list.filter(item => item !== object_number);
  setMyListCookie(newList);
  return newList;
};

export const clearMyList = () => {
  setMyListCookie(undefined);
};
